.Team-body {
  padding-inline: 2rem;
}

.Team-heading {
  text-align: center;
  font-size: 3em;
  color: #d6d5d7;
  padding-block: 1rem;
}

.Team-row {
  display: flex;
  flex-wrap: wrap;
  padding-block: 1rem;
  text-align: center;
  gap: 20px;
  justify-content: center;
}

.Team-row h1 {
  width: 100%;
  text-align: center;
  font-size: 2.5em;
  color: #d6d5d7;
}

.Team-btn {
  background-color: #fff;
  color: #011662;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
  margin-inline: 40px;
  transition: all 0.3s ease-in-out;
}

.Team-btn:hover {
  background-color: #d6d5d7;
  color: #1f003b;
  scale: 1.1;
}

.Team-btn.active {
  background-color: #120266;
  color: #fff;
  scale: 1.1;
}

@media screen and (max-width: 768px) {
  .Team-row {
    padding-block: 0.5rem;
  }
  .Team-btn {
    margin-inline: 0px;
    font-size: 1em;
  }
}
