.TeamCard-card {
  width: 280px;
  height: 380px;
  padding: 2.5em 1em;
  border-radius: 0.6em;
  cursor: pointer;
  transition: all 0.3s linear;
  background: linear-gradient(#120266, #3f1ec4);
  color: #ffffff;
  justify-content: space-evenly;
}

.TeamCard-card:hover {
  color: #1f003b;
  background: #ffffff;
}

.TeamCard-img-container {
  background-color: #e624ac;
  padding: 0.5em;
  border-radius: 50%;
  margin: 0 auto 3em auto;
  transition: all 0.3s linear;
  width: 70%;
}

.TeamCard-card:hover .TeamCard-img-container {
  width: 60%;
  margin-bottom: 2rem;
}

.TeamCard-card img {
  width: 100%;
  border-radius: 50%;
}

.TeamCard-card h3 {
  font-weight: 500;
  transition: all 0.3s linear;
}

.TeamCard-card p {
  font-weight: 300;
  text-transform: uppercase;
  margin: auto;
  letter-spacing: 2px;
}

.TeamCard-icons {
  width: 100%;
  margin: auto;
  margin-top: 0.5em;
  display: flex;
  justify-content: space-evenly;
}

.TeamCard-card a {
  text-decoration: none;
  color: inherit;
  font-size: 1.6rem;
  scale: 0;
  transition: all 0.3s linear;
}

.TeamCard-card:hover a {
  scale: 1;
}

.TeamCard-card .TeamCard-img-container {
  transform: scale(1.3);
}

.TeamCardImage {
  /* width: 100px !important;
  height: 100px !important; */
  aspect-ratio: 1;
}
