@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideInLeft {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInUp {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slideInLeft {
  animation: slideInLeft 1.25s ease both;
}

.animate-slideInRight {
  animation: slideInRight 1.25s ease both;
}

.animate-slideInUp {
  animation: slideInUp 1.5s ease both;
}
